import {ContentBundle} from '../../content-bundle/content-bundle';
import {Automation} from '../../automation/automation';
import {AutomationConfiguration} from './automation-configuration';

export class CustomAutomation extends Automation {
  config: AutomationConfiguration;
  content_bundle: ContentBundle;

  constructor(data: Partial<CustomAutomation>) {
    super(data);
    if (data.config) {
      this.config = new AutomationConfiguration(data.config);
    }
    if (data.content_bundle) {
      this.content_bundle = new ContentBundle(data.content_bundle);
    }
  }

  public title(): string {
    if (!this.content_bundle || this.content_bundle.content_elements.length === 0) {
      return null;
    }

    const titleElement = this.content_bundle.content_elements
      .filter(ce => ['heading1', 'heading2', 'heading3', 'deal', 'text'].includes(ce.element_type))[0];

    if (!titleElement) {
      return null;
    } else if (titleElement.element_type === 'deal') {
      return titleElement.deal.title;
    } else {
      return titleElement.data.text;
    }
  }

  public valid(): boolean {
    return this.configValid() && this.contentValid();
  }

  public configValid(): boolean {
    return !(!this.config || !this.config.valid());
  }

  public contentValid(): boolean {
    if (!this.content_bundle) {
      return false;
    }
    return this.content_bundle.content_elements.every(ce => ce.valid());
  }

  public asRequest(): Partial<CustomAutomation> | any {
    return {
      id: this.id,
      active: this.active,
      config: this.config ? this.config.asRequest() : null
    };
  }
}
